<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-02-24 17:03:32
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-28 20:21:22
-->
<template>
  <div class="cus_sum">
    <a-form-model :labelCol="{span: 8}" :wrapperCol="{span:16}">
      <a-row :gutter="4">
        <a-col :xxl="6" :xl="6" :md="8" :sm="12">
          <a-form-model-item label="经销商名称">
          <a-input v-model.trim="page.FName" placeholder="请输入经销商名称" :max-length="50"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="6" :md="8" :sm="12">
          <a-form-model-item :labelCol="{span: 0}" :wrapperCol="{span:22,offset:1}">
          <a-button @click="getData(0)" type="primary" icon="search">查询</a-button>
          <a-button @click="getData(1)" type="default" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <a-table 
    :data-source="tableData" 
    bordered  
    :pagination="page" 
    :columns="columns"
    @change="changeTable"
    :row-selection="{fixed: true,type: 'radio',selectedRowKeys:selectedRowKeys,onChange: onSelectChange}">
    </a-table>
    <CusChart :option="charts" :id="'1'" ref="cusChart"></CusChart>
  </div>
</template>

<script>
  import { CusColumns } from './components/colums.js'
  import { CusSumData } from './components/TableData.js'
  import CusChart from './components/CusChart'
  const title = {
    text: '各个经销商交易额达成情况'
  }
  const xAxis = {
    categories: ['一月', '二月', '三月', '四月', '五月', '六月','七月', '八月', '九月', '十月', '十一月', '十二月']
  }
  const legend = {
    layout: 'horizontal',
    verticalAlign: 'bottom',
    borderWidth: 0
  }
  export default {
    data() {
      return {
        columns: CusColumns,
        tableData: [],
        selectedRowKeys: [],
        selectedRows: [],
        chartList: [],
        charts: {
          series: [
            {
              type: 'spline',
              name: '交易情况',
              data: []
            }, 
          ],
          xAxis: xAxis,
          legend: legend,
          title: title
        },
        page: {
          pageSize: 10,
          pageNumber: 1,
          total: 0,
          fname: ''
        }
      }
    },
    created() {
      this.getData(0)
    },
    components: {
      CusChart
    },
    
    methods: {
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
        this.chartList = [this.selectedRows[0].january,this.selectedRows[0].february,this.selectedRows[0].march,this.selectedRows[0].april,this.selectedRows[0].may,this.selectedRows[0].june,this.selectedRows[0].july,this.selectedRows[0].august,this.selectedRows[0].september,this.selectedRows[0].october,this.selectedRows[0].november,this.selectedRows[0].december]
        this.$set(this.charts.series[0], 'data', this.chartList)
        this.$refs.cusChart.getCharts()
      },
      getData(type) {
        if(type == 1){
          this.page = {
            pageSize: 10,
            pageNumber: 1,
            total: 0,
            fname: ''
          }
        }
        this.axios.get('/api/customer/customer/customerInfo/listCustomerTurnover', {params: this.page}).then(res => {
          if(res.code == 200){
            this.tableData = res.body.records
            this.page.total = res.body.total
            this.chartList = [this.tableData[0].january,this.tableData[0].february,this.tableData[0].march,this.tableData[0].april,this.tableData[0].may,this.tableData[0].june,this.tableData[0].july,this.tableData[0].august,this.tableData[0].september,this.tableData[0].october,this.tableData[0].november,this.tableData[0].december]
            this.$set(this.charts.series[0], 'data', this.chartList)
            this.$refs.cusChart.getCharts()
          }
        })
      },
      /**
       * 更改分页
       */
      changeTable(pagination) {
          this.page = pagination
          this.page.pageNumber = pagination.current
          this.getData(0)
      },
    }
  }
</script>

<style lang="scss">
  
</style>